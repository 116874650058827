import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import { Box, Text } from 'rebass/styled-components'

const TitleDivider = styled(Box)`
  width: 5rem;
  height: 4px;
  background-color: ${(props) => props.theme.colors.gold};
`

const InfoBox = ({ label, value, unit, largeValue }) => (
  <Box
    sx={{
      p: 3,
      color: 'body',
    }}
  >
    <Text
      as="p"
      sx={{
        position: 'relative',
        letterSpacing: '0.0625rem',
        lineHeight: 'body',
        textTransform: 'uppercase',
        mb: 2,
      }}
    >
      {label}
    </Text>

    <TitleDivider mb={2} />

    <Text
      sx={{
        fontWeight: 'bold',
        lineHeight: 'body',
        fontSize: largeValue ? 8 : 'body',
      }}
    >
      {`${value}`}
      {unit && ` ${unit}`}
    </Text>
  </Box>
)

const BeerInfo = ({ info }) => {
  return (
    <Box
      sx={{
        display: 'grid',
        columnRap: 4,
        gridTemplate: ['auto / auto auto'],
      }}
    >
      <InfoBox
        label={
          <FormattedMessage id="beer.info.alcohol" defaultMessage="Alkohol" />
        }
        value={info.alcohol}
        unit="%"
        largeValue
      />
      <InfoBox
        label={
          <FormattedMessage id="beer.info.extract" defaultMessage="Extrakt" />
        }
        value={info.extract}
        unit="%"
        largeValue
      />
      <InfoBox
        label={<FormattedMessage id="beer.info.malt" defaultMessage="Slad" />}
        value={info.malt}
      />
      <InfoBox
        label={(
          <FormattedMessage
            id="beer.info.bitterness"
            defaultMessage="Hořkost"
          />
        )}
        value={info.bitterness}
        unit="IBU"
      />
      <InfoBox
        label={<FormattedMessage id="beer.info.hop" defaultMessage="Chmel" />}
        value={info.hop}
      />
      <InfoBox
        label={<FormattedMessage id="beer.info.color" defaultMessage="Barva" />}
        value={info.color}
        unit="EBC"
      />
    </Box>
  )
}

InfoBox.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  unit: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  largeValue: PropTypes.bool,
}

InfoBox.defaultProps = {
  unit: null,
  largeValue: false,
}

BeerInfo.propTypes = {
  info: PropTypes.shape({
    alcohol: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    extract: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    malt: PropTypes.string,
    bitterness: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    hop: PropTypes.string,
    color: PropTypes.string,
  }).isRequired,
}

export default BeerInfo
