import React from 'react'
import PropTypes from 'prop-types'
import { graphql, navigate } from 'gatsby'
import Image from 'gatsby-image'
import { FormattedMessage } from 'react-intl'
import { Box, Flex, Heading, Text, Button } from 'rebass/styled-components'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { SectionWithObjects, SectionGold } from '../components/section'
import Container from '../components/container'
import Hero from '../components/hero'
import NextStep from '../components/next-step'
import BeerInfo from '../components/beer-info'
import IconCutlery from '../images/inline/icon-cutlery.svg'
import IconThermometer from '../images/inline/icon-thermometer.svg'
import IconAward from '../images/inline/icon-award.svg'
import { normalizeBeer } from '../utils/normalizeBeers'
import getLocale from '../utils/getLocale'
import getTranslations from '../utils/getTranslations'

const BeerTextInfo = ({ children }) => (
  <Flex sx={{ alignItems: 'center', mb: 4 }}>{children}</Flex>
)

const BeerBottleGlassImage = ({ fixed, alt }) => {
  return (
    <Image
      fixed={fixed}
      alt={alt}
      style={{
        maxWidth: '360px',
      }}
      imgStyle={{
        objectPosition: 'right center',
      }}
    />
  )
}

const IconWrapper = ({ children }) => (
  <Box
    sx={{
      mr: 3,
      p: 3,
      flexShrink: 0,
      bg: 'gold',
      borderRadius: 'circle',
      lineHeight: 0,
    }}
  >
    {children}
  </Box>
)

const Beer = ({ pageContext, data }) => {
  const { heroBackgroundImage, nextStepBackground, beerPlaceholder } = data
  const { name, description, etiquette, info, translations, beerBottle } =
    normalizeBeer(data.wordpressWpBeerType)
  const { beersPage, locationsPage } = data
  const { locale } = pageContext

  return (
    <Layout
      locale={locale}
      pageTranslationLinks={getTranslations(
        locale,
        translations.polylang_translations,
        true,
      )}
    >
      <SEO title={name} />

      <Hero
        title={name}
        html={description}
        backgroundImage={heroBackgroundImage}
        backgroundPosition="50% 20%"
        etiquette={etiquette}
      />

      {info && (
        <SectionWithObjects>
          <Container>
            <Heading as="h2" variant="sectionTitle">
              <FormattedMessage id="beer.aboutBeer" defaultMessage="O pivu" />
            </Heading>

            <Box
              sx={{
                display: 'grid',
                alignItems: ['start', 'start', 'center'],
                gap: [2, 3, 4],
              }}
            >
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: ['1fr', '1fr', 'repeat(3, 1fr)'],
                  gap: 4,
                }}
              >
                {info.foodPairing && (
                  <BeerTextInfo>
                    <IconWrapper>
                      <IconCutlery />
                    </IconWrapper>

                    <Text as="p" variant="beerInfo">
                      {info.foodPairing}
                    </Text>
                  </BeerTextInfo>
                )}

                {info.temperature && (
                  <BeerTextInfo>
                    <IconWrapper>
                      <IconThermometer />
                    </IconWrapper>

                    <Text as="p" variant="beerInfo">
                      <FormattedMessage
                        id="beer.info.temperature"
                        defaultMessage="Teplota skladování: {temperature} °C"
                        values={{
                          temperature: info.temperature,
                        }}
                      />
                    </Text>
                  </BeerTextInfo>
                )}

                {info.awards && (
                  <BeerTextInfo>
                    <IconWrapper>
                      <IconAward />
                    </IconWrapper>

                    <Box>
                      {info.awards.map((award) => (
                        <Text key={award} as="p" variant="beerInfo">
                          {award}
                        </Text>
                      ))}
                    </Box>
                  </BeerTextInfo>
                )}
              </Box>

              <Box
                sx={{
                  display: 'grid',
                  gridAutoFlow: ['row', 'column', 'column'],
                  justifyItems: 'center',
                  p: 3,
                  bg: 'white',
                  borderRadius: '6px',
                }}
              >
                <BeerInfo info={info} />

                {beerBottle && (
                  <BeerBottleGlassImage fixed={beerBottle} alt={name} />
                )}

                {!beerBottle && beerPlaceholder && (
                  <BeerBottleGlassImage
                    fixed={beerPlaceholder.childImageSharp.fixed}
                    alt={name}
                  />
                )}
              </Box>
            </Box>
          </Container>
        </SectionWithObjects>
      )}

      <SectionGold pt={4} pb={4}>
        <Container>
          <Flex
            sx={{
              flexDirection: ['column', 'column', 'row'],
              alignItems: ['center', 'center'],
              justifyContent: ['flex-start', 'center'],
            }}
          >
            <Heading
              as="h2"
              variant="sectionTitle"
              sx={{ mr: [0, 0, 4], mb: [3, 3, 0], color: 'darkGray' }}
            >
              <FormattedMessage
                id="beer.backToAllBeers"
                defaultMessage="Další opice"
              />
            </Heading>

            <Button
              variant="outlineDark"
              onClick={() => navigate(`/${getLocale(locale)}/${beersPage.slug}`)}
            >
              <FormattedMessage id="beers.all" defaultMessage="Všechny opice" />
            </Button>
          </Flex>
        </Container>
      </SectionGold>

      <NextStep
        titleId="beer.nextStep.title"
        titleDefaultMessage="Máte chuť na pivo? Podívejte se kam na něj můžete skočit."
        link={`/${getLocale(locale)}/${locationsPage.slug}/`}
        labelId="beer.nextStep.label"
        labelDefaultMessage="Kam na pivo"
        backgroundImage={nextStepBackground}
      />
    </Layout>
  )
}

Beer.propTypes = {
  pageContext: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
}

BeerTextInfo.propTypes = {
  children: PropTypes.node.isRequired,
}

IconWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

BeerBottleGlassImage.propTypes = {
  fixed: PropTypes.object.isRequired,
  alt: PropTypes.string,
}

export default Beer

export const pageQuery = graphql`
  query BeerDetailQuery($locale: String, $key: String) {
    wordpressWpBeerType(slug: { eq: $key }) {
      ...BeerTranslations
      slug
      title
      polylang_current_lang
      acf {
        barva
        chmel
        etiquette {
          background_png {
            ...EtiquettePNGBackground
          }
          top_png {
            ...EtiquettePNGTop
          }
          bottom_png {
            ...EtiquettePNGBottom
          }
        }
        beer_bottle {
          localFile {
            childImageSharp {
              fixed(quality: 80, width: 360, height: 360) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
        extrakt
        hlaska_dostupnosti
        horkost
        idealni_teplota
        informace_o_dostupnosti
        kombinace_s_jidlem
        nazev_piva
        obsah_alkoholu
        popis_piva
        slad
        text_oceneni
      }
    }
    beersPage: wordpressPage(
      polylang_current_lang: { eq: $locale }
      template: { eq: "page-nase-opice.php" }
    ) {
      slug
    }
    locationsPage: wordpressPage(
      polylang_current_lang: { eq: $locale }
      template: { eq: "page-kam-na-pivo.php" }
    ) {
      slug
    }
    beerPlaceholder: file(
      relativePath: { eq: "placeholder-lahev-sklenice.png" }
    ) {
      childImageSharp {
        fixed(quality: 80, width: 360, height: 360) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    heroBackgroundImage: file(relativePath: { eq: "tap.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1280) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    nextStepBackground: file(relativePath: { eq: "tap.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
